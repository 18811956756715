// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React from 'react'
// import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components
import ConfigProvider from 'antd/lib/config-provider'
import '@bodhi-project/antrd/lib/4.10.3/config-provider/style/css'

import StandardPageWrapper from '../components/standard-page-wrapper'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals
import seoHelper from '../methods/seoHelper'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
// const { Fragment } = React
const date = '​18/01/2021'
const project = 'Prisma​'
const website = 'https://arts.prisma.haus​'

const pageData = {
  pageTitle: 'Terms and Conditions',
  nakedPageSlug: 'terms-and-conditions',
  pageAbstract: `Last updated: ${date}. Please read these Terms and Conditions ("Terms", "Terms and Conditions") carefully before using ${website} and related applications ("Services") operated by ${project}​ ("us", "we", or "our"). Your access to and use of the Service is conditioned on your acceptance of and compliance with these Terms. These Terms apply to all visitors, users and others who access or use the Service. By accessing or using the Service you agree to be bound by these Terms. If you disagreewith any part of the terms then you may not access the Service.`,
}

const seoData = seoHelper(pageData)

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Images
// ----------------------------------------------------------------------------
export const query = graphql`
  query {
    launch: file(relativePath: { eq: "launch.jpg" }) {
      ...max900
    }
  }
`

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/** Page */
const Page = props => (
  <ConfigProvider prefixCls="wtf">
    <StandardPageWrapper className="" seoData={seoData} {...props}>
      <h1 className="as-h2">Terms and Conditions</h1>
      <p className="abstract">Last updated: {date}</p>
      <p>
        Please read these Terms and Conditions ("Terms", "Terms and Conditions")
        carefully before using {website} and related applications ("Services")
        operated by {project}​ ("us", "we", or "our"). Your access to and use of
        the Service is conditioned on your acceptance of and compliance with
        these Terms. These Terms apply to all visitors, users and others who
        access or use the Service. By accessing or using the Service you agree
        to be bound by these Terms. If you disagree with any part of the terms
        then you may not access the Service.
      </p>
      <h2 className="block-heading">Purchases</h2>
      <p>
        If you wish to purchase any product or service made available through
        the Service ("Purchase"), you may be asked to supply certain information
        relevant to your Purchase.
      </p>
      <h2 className="block-heading">Links To Other Web Sites</h2>
      <p>
        Our Service may contain links to third-party web sites or services that
        are not owned or controlled by {project}. {project} has no control over,
        and assumes no responsibility for, the content, privacy policies, or
        practices of any third party web sites or services. You further
        acknowledge and agree that ​{project} shall not be responsible or
        liable, directly or indirectly, for any damage or loss caused or alleged
        to be caused by or in connection with use of or reliance on any such
        content, goods or services available on or through any such web sites or
        services.
      </p>
      <h2 className="block-heading">Changes</h2>
      <p>
        We reserve the right, at our sole discretion, to modify or replace these
        Terms at any time. If a revision is material we will try to provide at
        least ​30 days' notice prior to any new terms taking effect. What
        constitutes a material change will be determined at our sole discretion.
      </p>
      <h2 className="block-heading">Contact Us</h2>
      <p>
        If you have any questions about these Terms and Conditions, please
        contact us.
      </p>
    </StandardPageWrapper>
  </ConfigProvider>
)

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Export
// ----------------------------------------------------------------------------
export default Page
